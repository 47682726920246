import {
  FingerPrintIcon,
  ShieldCheckIcon,
  ViewListIcon,
} from "@heroicons/react/outline"
import { ArrowCircleLeftIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import React from "react"
import FeatureBox from "../../common/FeatureBox"

const RequestLeftCol = props => {
  return (
    <div className="flex-col lg:flex">
      <Link
        to="/"
        className="inline-flex w-full mb-8 transition duration-300 hover:text-brand-yellow-dark"
      >
        <ArrowCircleLeftIcon className="w-6 h-6 mr-3 text-brand-yellow-dark" />
        Zurück zur letzten Seite
      </Link>

      <div className="w-full mb-5 text-left">
        <FeatureBox
          svg={<ShieldCheckIcon className="w-6 h-6 text-white" />}
          text="Verschlüsselte Übertragung"
          subText="Deine Daten werden per SSL-Verschlüsselung übertragen."
        />
      </div>
      <div className="w-full mb-5 text-left">
        <FeatureBox
          svg={<ViewListIcon className="w-6 h-6 text-white" />}
          text="Respekt vor Daten"
          subText="Wir erheben nur Daten, die zur Bearbeitung Deiner Anfrage benötigt werden."
        />
      </div>
      <div className="w-full mb-5 text-left">
        <FeatureBox
          svg={<FingerPrintIcon className="w-6 h-6 text-white" />}
          text="Keine Weitergabe"
          subText="Deine Daten werden nur verwendet, um Deine Anfrage zu bearbeiten. Wir geben Deine Daten nicht weiter & verwenden diese nicht anderweitig."
        />
      </div>
    </div>
  )
}

export default RequestLeftCol
