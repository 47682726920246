import React, { useRef, useState } from "react"
import { PlayIcon } from "@heroicons/react/solid"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

interface ImageVideoProps {
  video: string
  image: IGatsbyImageData
  alt: string
  className?: string
}

const ImageVideo: React.FC<ImageVideoProps> = ({
  video,
  image,
  alt,
  className = "",
}) => {
  const [isVideoActive, setIsVideoActive] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const imageCover = getImage(image)

  if (!imageCover) {
    throw new Error("Failed to process image")
  }

  const handleVideoPlay = (): void => {
    setIsVideoActive(true)
    videoRef.current?.play()
  }

  const renderPlayButton = (): JSX.Element => (
    <div
      className={`absolute top-0 left-0 z-10 rounded-lg flex w-full h-full transition-colors duration-300 hover:bg-gray-900 hover:opacity-80 items-center justify-center`}
    >
      <PlayIcon className="w-16 h-16 text-white" />
    </div>
  )

  return (
    <div className={`pb-0 mx-auto rounded-lg ${className}`}>
      {!isVideoActive && (
        <div
          className="relative cursor-pointer shadow rounded-lg w-full"
          onClick={handleVideoPlay}
          onKeyPress={(e: React.KeyboardEvent) =>
            e.key === "Enter" && handleVideoPlay()
          }
          role="button"
          tabIndex={0}
        >
          {renderPlayButton()}
          <GatsbyImage
            image={imageCover}
            alt={alt}
            className="align-top rounded-lg"
          />
        </div>
      )}
      <video
        controls
        ref={videoRef}
        className={isVideoActive ? "block rounded-lg" : "hidden"}
      >
        <source src={video} type="video/mp4" />
        Dein Browser unterstützt das Video-Tag nicht.
      </video>
    </div>
  )
}

export default ImageVideo
