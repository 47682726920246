import React from "react"

const FeatureBox = ({ svg, text, subText, href }) => {
  return (
    <div className="flex flex-row items-stretch">
      {href ? (
        <a href={href} className="flex items-stretch">
          <div className="flex items-center p-2 mr-3 text-white rounded shadow bg-brand-yellow hover:bg-opacity-70">
            {svg}
          </div>
        </a>
      ) : (
        <div className="flex items-center p-2 mr-3 text-white rounded shadow bg-brand-yellow">
          {svg}
        </div>
      )}

      <div className="flex-1">
        <div className="font-bold">{text}</div>
        {subText && <div>{subText}</div>}
      </div>
    </div>
  )
}

export default FeatureBox
