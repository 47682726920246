import React from "react"
import { graphql } from "gatsby"
import Button from "../components/common/Button"
import ImageVideo from "../components/common/ImageVideo"
import Layout from "../components/layouts/Default"
import RequestLeftCol from "./../components/layouts/partials/RequestLeftCol"
import RequestRightCol from "./../components/layouts/partials/RequestRightCol"
import video from "../media/Baden-TV_SEO-Optimierung.mp4"
import P from "../components/typography/P"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Anfrage gesendet SEO Agentur Stuttgart"
      desc=""
      noHeader
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <RequestRightCol>
              <h1 className="mb-5 text-2xl font-bold md:text-3xl lg:text-4xl">
                Anfrage erfolgreich versendet
              </h1>
              <p className="max-w-lg mb-5">
                Vielen Dank für Deine Anfrage. Ich werde mich zeitnah bei Dir
                melden. Für unser Beratungsgespräch empfehle ich Dir folgendes
                Interview anzuschauen. Dort werden die häufigsten Fragen
                beantwortet.
              </p>
              <div className="max-w-lg mb-5">
                <ImageVideo video={video} image={data.videoCover} />
              </div>

              <Button
                href="https://calendly.com/oliver-e-bahm/seo-premium-agentur"
                target="_blank"
                rel="noopener noreferrer"
                text="Terminvereinbarung"
                turquoise
                className="mb-3"
              />
              <P>
                Oder ruf mich gleich an:{" "}
                <a href="tel:+491634580757" className="underline">
                  0163 4580 757
                </a>
              </P>
            </RequestRightCol>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    videoCover: file(relativePath: { eq: "Baden-TV_SEO-Optimierung.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
